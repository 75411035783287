import request from './request';
const MODE = process.env.VUE_APP_MODE;
let BASEURL = '';//速派接口
let ACTIVITYURL = '';//活动接口
console.log("MODE:"+MODE);
if (MODE == 'test') {
    //预生产
    ACTIVITYURL = 'https://tactivityapi.insupai.com';
    BASEURL = 'https://test.api.insupai.com';
}
if (MODE == 'production') {
    //生产
    ACTIVITYURL = 'https://activityapi.insupai.com';
    BASEURL = 'https://api.insupai.com';
}
if (MODE == 'development') {
    //测试
    ACTIVITYURL = 'http://activityapi.qa.gongkongbpo.com';
    BASEURL = 'http://api.qa.gongkongbpo.com';
    // BASEURL = 'https://api.insupai.com';
}
//百度地图获取坐标城市
export function getLocation() {
    return request('jsonp', 'https://api.map.baidu.com/location/ip?ak=ia6HfFL660Bvh43exmH9LrI6');
}
//西门子活动报名
export function SignUp(params) {
    return request('post', ACTIVITYURL + '/api/SiemensActivity/SignUp', params);
}
//手机验证码
export function SendMobileCode(params) {
    return request('post', BASEURL + '/ChuJu/HYCommon/SendMobileCode', params);
}
//个人用户活动注册
export function UserActivityRegister(params) {
    return request('post', BASEURL + '/api/Account/AccountAuth/UserActivityRegister', params);
}
//邀请工程师活动视图
export function GetInviteEngineerActivityVO(params) {
    return request('post', ACTIVITYURL + '/api/InviteEngineer/GetInviteEngineerActivityVO', params);
}
//领U盘
export function AcceptPrize(params) {
    return request('post', ACTIVITYURL + '/api/InviteEngineer/AcceptPrize', params);
}
//被邀请人领U盘
export function InvitedUserAcceptPrize(params) {
    return request('post', ACTIVITYURL + '/api/InviteEngineer/InvitedUserAcceptPrize', params);
}
//添加收货地址
export function AddShippingAddress(params) {
    return request('post', ACTIVITYURL + '/api/InviteEngineer/AddShippingAddress', params);
}
//获取邀请人信息
export function GetInviterInfo(params) {
    return request('post', ACTIVITYURL + '/api/InviteEngineer/GetInviterInfo', params);
}


// 抽奖结果滚动条
export function ListDrawPrizeScrollInfo(params) {
    return request('post', ACTIVITYURL + '/api/Sign/ListDrawPrizeScrollInfo', params);
}
// 获取签到活动数据
export function GetSignAcivityData(params) {
    return request('post', ACTIVITYURL + '/api/Sign/GetSignAcivityData', params);
}
// 签到
export function SetSign(params) {
    return request('post', ACTIVITYURL + '/api/Sign/Sign', params);
}
// 抽奖
export function DrawPrize(params) {
    return request('post', ACTIVITYURL + '/api/Sign/DrawPrize', params,false);
}
// 中奖纪录
export function ListDrawPrizeRes(params) {
    return request('post', ACTIVITYURL + '/api/Sign/ListDrawPrizeRes', params,false);
}
// 分享邀请活动结果滚动条
export function InviteWinPrizeScrollList(params) {
    return request('post', ACTIVITYURL + '/api/InvitationActivity/InviteWinPrizeScrollList', params);
}
// 分享邀请活动中奖列表
export function InviteWinPrizeList(params) {
    return request('post', ACTIVITYURL + '/api/InvitationActivity/InviteWinPrizeList', params);
}


// 工单详情
export function GetJobDetailIndex(params) {
    return request('post', BASEURL + '/api/BpoJob/GetJobDetailIndex', params);
}
// 获取会员中心信息展示数据
export function GetMemberCenterInfo(params) {
    return request('post', BASEURL+'/api/Account/AccountMember/GetMemberCenterInfo', params);
}